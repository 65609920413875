<template>
    <div class="w3-theme-l1" style="">
        <div class="w3-container w3-margin-top" style="padding-bottom:10px">
            <Toolbar  
                :click-add="showAdd"
                :click-refresh="loadData"
                :click-print="showPrint"
                :status-print="false"
                :set-filter="setFilter"
                :field-find="filters.fields"
                :data   = "recs"
                :fields = "json_fields"
                type    = "xls"
                name    = "agama.xls"
                help = "aQSmjMLLvzc"
            />
            <div @mouseover="grid = true" @mouseleave="grid = false">
                <vue-scrolling-table v-bind:style="'height:'+  (screenHeight-149) +'px;'" :class="'w3-table w3-striped w3-white w3-small freezeFirstColumn'" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="true" id="grid">
                    <template slot="thead">
                        <tr class="w3-border">
                            <th style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                No
                            </th>
                            <th v-for="fl in filterByShow" :key="fl.key" :style="'min-width:' + fl.width"  class="w3-light-grey w3-border-0" >{{fl.name}}</th>
                        </tr>
                    </template>
                    <template slot="tbody">
                        <tr :id="'grid-' + index" @mouseover="dtl['pilih']=true" @mouseleave="dtl['pilih']=false" v-for="(dtl, index) in recs" style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index == selectedRow ?'bayangan w3-theme-l4':'w3-opacity-min'))"  :key="dtl.agama_code" v-on:dblclick="showEdit(dtl, index);" class="w3-border-bottom w3-hover-border-grey">
                            <td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>{{index+1}}
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','agama_name')" class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.agama_name,'agama_name')"></span>
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','date_edit')" class="w3-border-0 w3-border-left">
                                {{dtl.date_edit | moment("DD-MM-yyyy HH:mm:ss")}}
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','user_edit')" class="w3-border-0 w3-border-left">
                                <span v-html="setTextFind(dtl.user_edit,'user_edit')"></span>
                            </td>
                            <td style="padding: 3px 0px 0px 10px;" :style="'min-width:' + findObjFind('width','agama_status')" class=" w3-hide-small w3-hide-medium w3-center w3-border-left w3-border-right" >
                                <div v-on:click="setKlikRow(index, dtl)" style="position: relative;" :style="'min-width:' + findObjFind('width','agama_status')">
                                    <span v-html="setStatus(dtl.agama_status)"></span>
                                    <div v-show="dtl['pilih']==true && dtl.agama_status=='T'" style="position: absolute; top: -5px; left: -5px; text-align:center;" class="w3-opacity-off">
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showEdit(dtl, index)"><i class="fa fa-pencil w3-large"></i></button>
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="showDelete(dtl, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template slot="tfoot">
                        <tr style="display: block;">
                            <td style="padding: 0px;" :style="'display: contents;'">
                                <Paging class="w3-center"
                                    :click-handler="loadPage"
                                    :prev-text="'Prev'"
                                    :next-text="'Next'"
                                    :container-class="'w3-bar'"
                                    :page-count="pages.ttlhalaman"
                                    :page-range="10"
                                    :margin-pages="2"
                                    :value="pages.halaman">
                                </Paging>
                            </td>
                        </tr>
                    </template>
                </vue-scrolling-table>
            </div>
        </div>
        <!-- Modal -->
        <div id="id01" class="w3-modal" v-if="fieldRules.length>0">
            <vue-draggable-resizable :y="50" :drag-handle="'.drag'" style="width:100%;">
                <div class="w3-modal-content w3-animate-zoom w3-card-4" style="max-width:500px;">   
                    <header class="w3-container w3-theme-d3 drag" style="cursor:move;"> 
                        <span onclick="document.getElementById('id01').style.display='none'" 
                        class="w3-button w3-display-topright">&times;</span>
                        <h6><i class="fa fa-windows"></i> Add / Edit data</h6>
                    </header>
                    <div class="w3-container w3-padding w3-theme-l4">
                        <form method="post" id="frmBarang" v-on:submit.prevent="saveData" action="#" autocomplete="off">
                            <div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="agama_code">{{findObjFind('name','agama_code')}} :</label>
                                </div>
                                <div class="w3-col l2 m6 s12">
                                    <DeObject :DRules="findField('agama_code')" :errorMessage="errorMessage.agama_code" :showMessage=false :DModel="rec.agama_code">
                                        <template slot="field">
                                            <input :maxlength="findField('agama_code')['rules']['max-length']" :minlength="findField('agama_code')['rules']['min-length']" :required="findField('agama_code')['rules']['required']" type="text" ref="agama_code" class="w3-input w3-border w3-small w3-round-large" id="agama_code" :placeholder="findObjFind('name','agama_code')" v-model="rec.agama_code">
                                        </template>
                                    </DeObject>
                                </div>
                            </div>

                            <div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="agama_name">{{findObjFind('name','agama_name')}} :</label>
                                </div>
                                <div class="w3-col l10 m6 s12">
                                    <DeObject :DRules="findField('agama_name')" :errorMessage="errorMessage.agama_name" :showMessage=false :DModel="rec.agama_name">
                                        <template slot="field">
                                            <input :maxlength="findField('agama_name')['rules']['max-length']" :minlength="findField('agama_name')['rules']['min-length']" :required="findField('agama_name')['rules']['required']" type="text" ref="agama_name" class="w3-input w3-border w3-small w3-round-large" id="agama_name" :placeholder="findObjFind('name','agama_name')" v-model="rec.agama_name">
                                        </template>
                                    </DeObject>
                                </div>
                            </div>

                            <div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                    <label for="agama_status">&nbsp;</label>
                                </div>
                                <div class="w3-col l4 m9 s12">
                                    <label class="container w3-small">{{findObjFind('name','agama_status')}}
                                        <input v-model="rec.agama_status" type="checkbox" true-value="T" false-value="F">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <footer class="w3-container w3-theme-l1">
                        <label class="container w3-small w3-left" style="margin-top: 15px;">Auto add
                            <input true-value="T" false-value="F" v-model="pages.autoadd" type="checkbox" checked="checked">
                            <span class="checkmark w3-round"></span>
                        </label>
                        <h6 class="w3-right">
                            <button form="frmBarang" type="submit" class="w3-btn w3-small w3-theme-d5 w3-round" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
                            <button onclick="document.getElementById('id01').style.display='none'" class="w3-btn w3-small w3-red w3-round"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </footer>
                </div>
            </vue-draggable-resizable>
        </div>       
    </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
export default {
    head: {
        title: {
          inner: 'Konsultasi'
        },
    },
    name: 'Konsultasi',
    components: {},
    data() {
        return {
            err:'',
            grid : false,
            screenWidth : 0,
            screenHeight : 0,
            stsAdd : false,
            selectedRow : 0,
            errorMessage : {agama_name:''},
            fieldRules : [],
            recs : [],
            rec : {agama_code:'', agama_name : '', agama_status:'T'},
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, autoadd :'F'},
            filters : {
                operator : 'AND',
                fields: [
                    {fieldFind:'', key : 'agama_code', type: 'text', name : 'Kode', filter : false, width : '', grid : false},
                    {fieldFind:'', key : 'agama_name', type: 'text', name : 'Nama', filter : true, width : 'auto', grid : true},
                    {fieldFind:'', key : 'user_entry', type: 'text', name : 'User Entry', filter : false, width : '', grid : false},
                    {fieldFind:'', key : 'date_entry', type: 'text', name : 'Date Entry', filter : false, width : '', grid : false},
                    {fieldFind:'', key: "date_edit",type: "text",name: "Date Edit",filter: false,width: "150px",grid: true},
                    {fieldFind:'', key: "user_edit",type: "text",name: "Entry",filter: false,width: "150px",grid: true},
                    {fieldFind:'', key : 'agama_status', type: 'select', name : 'Status', filter : true, width : '80px', grid : true, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                ],
                find : []   
            },
            json_fields : {'Kode' : 'agama_code','Nama' : 'agama_name'},            
        };
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        setTextFind(txt,field){
            var fieldvalue = "";
            var ketemu = false;
            const self = this;
            if(self.filters.find!==undefined )
                if ( self.filters.find.length >0 ) {
                    self.filters.find.map(function(value){
                        if(value.field ===field){
                            ketemu = true;
                        }
                        if(fieldvalue!=='null')
                            fieldvalue += ((fieldvalue!=='')?';':'')+ value.fieldValue ;
                        else
                            fieldvalue += ((fieldvalue!=='null')?';':'')+ value.fieldValue ;
                    });
                }
            if(ketemu){
                return self.sethighlight(txt,fieldvalue);
            }else{
                return txt;
            }
        },
        loadPage (pageNum){
            this.pages.halaman = pageNum;
            this.loadData();
        },
        setFilter(filter, operator){
            this.filters.find = filter;
            this.filters.operator = operator;
            this.loadData();
        },
        loadData(){
            const self = this;
            let vparams = {
                operator: self.filters.find.length > 0 ? self.filters.operator : "AND",
                kondisi: "",
                field: "",
                fieldvalue: "",
                limit: self.pages.batas,
                offset: eval(self.pages.batas + "*(" + self.pages.halaman + "-1)")
            };

            if (self.filters.find.length > 0) {
                self.filters.find.map(function(value) {
                    vparams.kondisi += (vparams.kondisi !== "" ? ";" : "") + value.kondisi;
                    vparams.field += (vparams.field !== "" ? ";" : "") + value.field;
                    if (vparams.fieldvalue !== "null")
                        vparams.fieldvalue += (vparams.fieldvalue !== "" ? ";" : "") + value.fieldValue;
                    else
                        vparams.fieldvalue += (vparams.fieldvalue !== "null" ? ";" : "") + value.fieldValue;
                });
            }
            self.dePost('/hrd/agama/search',vparams,function(response){
                if(response.data.success==true){
                    self.selectedRow = -1;
                    self.recs = response.data.rec;
                    self.pages.ttlrec = parseFloat(response.data.ttlrec);
                    self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.batas);
                    self.fieldRules = response.data.rules;
                }else{
                    console.log('Gagal');
                }
            });
        },
        setKlikRow(index, dtl){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dtl);
        },
        showAdd(){
            let self = this;
            self.stsAdd = true;
            self.rec = { 
                agama_code : '',
                agama_name : '',
                agama_status : 'T',
                user_entry: localStorage.username,
                user_edit: localStorage.username,
                date_entry: moment(String(new Date())).format("YYYY-MM-DD"),
                date_edit: moment(String(new Date())).format("YYYY-MM-DD"),
            };
            if(document.getElementById('id01').style.display!='block')
                document.getElementById('id01').style.display='block';
            setTimeout(function(){
                document.getElementById('agama_code').focus();
            },500);
        },
        showEdit(dt, idx){
            this.selectedRow = idx;
            this.stsAdd = false;
            this.rec = Object.assign({}, dt);
            document.getElementById('id01').style.display='block';
        },
        showDelete(dt){
            const self = this;
            swal({
                title: "Yakin Ingin Menghapus Data ..!",
                text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#566D1D",
                confirmButtonText: "Ya, Hapus Data",
                cancelButtonText: "Tidak, Batal Hapus",
                closeOnConfirm: true,
                closeOnCancel: true
            },function(isConfirm){
                if (isConfirm) {
                    var vparams = {agama_code : dt.agama_code};
                    self.dePost('/hrd/agama/delete' ,vparams,function(response){
                        if(response.data.success==true){
                            self.$toast.success(self.setToast('Delete','Data Sudah terhapus','fa-remove',200));
                            self.$delete(self.recs,self.selectedRow);
                        }else{
                            self.$toast.error(self.setToast('Delete',response.data.msg,'fa-remove',200));
                        }
                    });                    
                }
            });
        },
        showPrint() {
            this.$toast.info('Under Production');
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            } else {
                return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
            }
        },
        saveData(event){
            const self = this;
            if(!self.getValid(self.rec,self.fieldRules)){
                return false;
            }
            self.dePost('/hrd/agama/' + (self.stsAdd==true ? 'add': 'edit') ,self.rec,function(response){
                if(response.data.success==true){
                    // swal({title: "Save",text: "Sukses Simpan data",type: "success", html : true, timer : 2000});
                    self.$toast.success(self.setToast('Save','Data Sudah tersimpan','',200));
                    if (self.stsAdd) {
                        self.recs.splice(0, 0, self.rec);
                    } else {
                        self.recs[self.selectedRow] = self.rec;
                    }
                    if(self.pages.autoadd=='T'){
                        self.showAdd();
                    }else{
                        document.getElementById('id01').style.display='none';
                    }
                }else{
                    self.$toast.error(self.setToast('Save',response.data.msg,'',200));
                }
            });
            event.preventDefault();
            return false;
        },
        
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        },
    },
    filters: {
        toRp (value) {
            return `${Intl.NumberFormat('de-DE').format(value)}`
        }
    },
    watch: {
        '$route': function(){
        }
    },
    mounted(){
        let self = this;
        self.pages = {halaman: 1,batas: self.perPage,ttlhalaman: 0,ttlrec: 0,autoadd: "F"};
        self.screenWidth = screen.width;
        self.screenHeight = window.innerHeight;
        self.eventGrid(self, 'grid', 'selectedRow', 'recs');
        self.getGridColumnmAutoWidth('grid',self.filters.fields, false);
        self.loadData();
    }
};
</script>